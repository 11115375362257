import React from "react"
import arrow from "../images/arrow.svg"
import mug from "../images/mug.svg"
import hammer from "../images/hammer.svg"
import headset from "../images/headset.svg"

export default () => 
<div id="startToFinish" class="section">
    <h2>We support you from start to finish to forever</h2>
    <p>We make sure that we not only give you a great solution but help you find the right problem and make sure that you’re never left on your own in the case of a problem.</p>  
    <div class="flex-container">
        <div class="flex-item primaryBox">
            <p class="title">Design & blueprinting</p>
            <p class="startToFinishText">You’re in safe hands with our bot design workshops, stakeholder demo days and a robust blueprinting process.</p>
            <div class="pictureAndText">
                <img src={mug} alt="mug"/>
                <p>Drop us a message and from day one we’ll be designing and refining your solution.</p>    
            </div>    
        </div>
        <img class="flex-item arrow" src={arrow} alt="arrow"/>
        <div class="flex-item primaryBox">
            <p class="title">Building & teaching</p>
            <p class="startToFinishText">Our full team is available for iterative, agile and experienced development that ensures the quality and speed of your bot.</p>
            <div class="pictureAndText">
                <img src={hammer} alt="hammer"/>
                <p>If you have your own resource to build your bot, we can assist with pair building or workshops.</p>    
            </div>    
        </div>
        <img class="flex-item arrow" src={arrow} alt="arrow"/>
        <div class="flex-item primaryBox">
            <p class="title">Support & monitoring</p>
            <p class="startToFinishText">We offer a comprehensive support offering.</p>
            <div class="pictureAndText">
                <img src={headset} alt="headset"/>
                <p></p>    
            </div>    
        </div>
    </div>
    <a target="_blank" rel="noopener noreferrer" href={"https://www.mhranalytics.com/talksuite/"}>
        <button className="longbutton">Read more about talksuite</button>
    </a>
</div>