import React from "react"
import botPlaceholder from "../images/bot-placeholder.jpg"
import swirlTop from "../images/swirl_top.svg"
import swirlBottom from "../images/swirl_bottom.svg"

export default () => 
<div class="examples">
    <img class="swirlTop" src={swirlTop} alt="swirlTop"/>
    <div class="flex-container section">
        <div class="flex-item">
            <div class="description">
                <h3>Smart FAQ</h3>
                Frequently asked questions shouldn’t be using up the valuable time of your support team. This bot automatically becomes a valuable member of your first line team.
            </div>
            <img class="botPlaceholder" src={botPlaceholder} alt="botPlaceHolder"/>
            <button class="longbutton">Learn more about this bot</button>
        </div>
        <div class="flex-item">
            <div class="description">
                <h3>Query anything</h3>
                In all sectors you’ll find jobs that deal with data; spreadsheets, CRMs, KPIs, databases. This bot comes prepackaged to help you find the data you need, incredibly easily.
            </div>
            <img class="botPlaceholder" src={botPlaceholder} alt="botPlaceHolder"/>
            <button class="longbutton">Learn more about this bot</button>
        </div>
        <div class="flex-item">
            <div class="description">
                <h3>Smalltalk</h3>
                Personality design of a bot is integral to its adoption and function. Smalltalk is a set of dialogues that provide a more personable conversation.
            </div>
            <img class="botPlaceholder" src={botPlaceholder} alt="botPlaceHolder"/>
            <button class="longbutton">Learn more about this bot</button>
        </div>
    </div>
    <img class="swirlBottom" src={swirlBottom} alt="swirlBottom"/>
</div>