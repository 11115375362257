import React from "react";
import ChatbotExamples from "../components/chatbotExamples"
import MeetTheTeam from "../components/meetTheTeam"
import HeroText from "../components/heroText"
import StartToFinish from "../components/startToFinish"
import Header from "../components/header"
import Footer from "../components/footer"
import whytalksuite3 from "../images/whytalksuite1.svg"
import whytalksuite1 from "../images/whytalksuite2.svg"
import whytalksuite2 from "../images/whytalksuite3.svg"

const Page = class extends React.Component {
    render() {
        return(
            <>
                <body class="mainPage">
                    <Header showMenu={true}  />
                    <div class="content">
                        <HeroText />
                        <MeetTheTeam />
                        <div class="section">
                            <h2>
                                Why is a bot the right solution for me?
                            </h2>
                            <div class="flex-container">
                                <div class="flex-item primaryBox">
                                    <div class="rightsol-text">
                                        Chat apps have taken over the way we communicate-
                                    </div>
                                    <div class="logo-container">
                                        <div class="oneLogo">
                                            <div class="logoPic">
                                                <img src="whatsapp-logo.png" alt="Whatsapp"/>
                                            </div>
                                            <div class="bigNumber hide-mobilex">
                                                1.7 billion
                                            </div>
                                            <div class="bigNumber show-mobilex">
                                                1.7 B
                                            </div>
                                        </div>
                                        <div class="oneLogo">
                                            <div class="logoPic">
                                                <img src="messenger.png" alt="Facebook Messenger"/> 
                                            </div>
                                            <div class="bigNumber hide-mobilex">
                                                1.3 billion
                                            </div>
                                            <div class="bigNumber show-mobilex">
                                                1.3 B
                                            </div>
                                        </div>
                                        <div class="oneLogo">
                                            <div class="logoPic">
                                                <img src="wechat.png" alt="WeChat"/>
                                            </div>
                                            <div class="bigNumber hide-mobilex">
                                                1.1 billion
                                            </div>
                                            <div class="bigNumber show-mobilex">
                                                1.1 B
                                            </div>
                                        </div>
                                    </div>
                                    <div class="rightsol-text">
                                    monthly active users.
                                    </div>
                                    
                                </div>
                                <div class="flex-item primaryBox">
                                    <div class="rightsol-text veryBigNumber">
                                        63%
                                    </div>
                                    <div class="rightsol-text">
                                        of consumers said that they are more likely to return to a website that offers live chat over phone support.
                                    </div>
                                    <div class="rightSolBotVsPhone">
                                        <div class="vsPic">
                                            <img src="np_chat_1822851_000000.png" alt="chatbubbles" />
                                        </div>
                                        vs
                                        <div class="vsPic">
                                            <img src="np_phone_2828836_000000.png" alt="phone" />
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-item primaryBox">
                                    <div class="rightsol-text">
                                        Popularity of bots have skyrocketed due to dramatic improvements in <b>natural language processing</b> and <b>machine learning.</b>
                                    </div>
                                    <div class="graph">
                                            <img src="np_graph_648718_000000.png" alt="graph" />
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="section">
                            <h2>Why is talksuite the right technology to use?</h2>
                            <div class="flex-container">
                                <div class="flex-item primaryBox">
                                    <div class= "flexMiddleWhyTS-container">
                                        <div class="flexMiddleWhyTS-item flexInnerWhyTS-container">
                                            <div class="flexInnerWhyTS-itemImage">
                                                <img class="flexInnerWhyTS-itemimage-img" src={whytalksuite1} alt="Enterprise grade platform"/>
                                            </div>
                                            <div class="flexInnerWhyTS-itemTitle">
                                                <text>Enterprise<br />grade<br />platform</text>
                                            </div>
                                        </div>
                                        <div class="flexMiddleWhyTS-item">
                                            <text>Talksuite is a <em>bot development platform</em> with advanced enterprise features which means you can be confident in the scale, capability and security of your bot.</text>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-item primaryBox">
                                    <div class= "flexMiddleWhyTS-container">
                                        <div class="flexMiddleWhyTS-item flexInnerWhyTS-container">
                                            <div class="flexInnerWhyTS-itemImage">
                                                <img class="flexInnerWhyTS-itemimage-img" src={whytalksuite2} alt="Global ready"/>
                                            </div>
                                            <div class="flexInnerWhyTS-itemTitle">
                                                <text>Global<br />ready</text>
                                            </div>
                                        </div>
                                        <div class="flexMiddleWhyTS-item">
                                            <text>Talksuite supports an unlimited amount of valid languages so your bot can be used in your global business without costly changes to your bots.</text>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-item primaryBox">
                                    <div class= "flexMiddleWhyTS-container">
                                        <div class="flexMiddleWhyTS-item flexInnerWhyTS-container">
                                            <div class="flexInnerWhyTS-itemImage">
                                                <img class="flexInnerWhyTS-itemimage-img" src={whytalksuite3} alt="One bot to rule them all"/>
                                            </div>
                                            <div class="flexInnerWhyTS-itemTitle">
                                                <text>One bot<br />to rule<br />them all</text>
                                            </div>
                                        </div>
                                        <div class="flexMiddleWhyTS-item">
                                            <text>Talksuite allows you to create a consumer-grade user experience that integrates with all of your systems so your employees or customers have one place for everything.</text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a target="_blank" rel="noopener noreferrer" href={"https://www.mhranalytics.com/talksuite/"}>
                            <button class="longbutton">Read more about talksuite</button>
                            </a>
                        </div>
                        <ChatbotExamples />
                        <StartToFinish />
                    </div>
                    <Footer />
                </body>
            </>
        )   
    }    
}

export default Page
