import React from "react"
import image1 from "../images/image_1.jpg"
import image2 from "../images/image_2.jpg"
import image3 from "../images/image_3.jpg"
import swirlTop from "../images/swirl_top.svg"
import swirlBottom from "../images/swirl_bottom.svg"

export default () => 
<div class="hero">
    <img class="swirlTop" src={swirlTop} alt="swirlTop"/>
    <div class="imageCycle"> 
        <img style={{animationDelay: '10s'}} src={image3} alt="image3"/>
        <img style={{animationDelay: '5s'}} src={image2} alt="image2"/>
        <img src={image1} alt="image1"/>
    </div>
    <img class="swirlBottom" src={swirlBottom} alt="swirlBottom"/>
    <text class="heroTextFixed">We're here to transform the way you</text>
    <div class="heroTextAnimationContainer"> 
        <text>sell</text>
        <text>support</text>
        <text>communicate</text>
    </div>
</div>
