import React from "react"
import phil from "../images/phil.jpg"
import jamesw from "../images/jamesw.jpg"
import craig from "../images/craig.jpg"
import dimitris from "../images/dimitris.jpg"
import william from "../images/william.jpg"
import jamesb from "../images/jamesb.jpg"
import harry from "../images/harry.jpg"
import peter from "../images/peter.jpg"
import louise from "../images/louise.jpg"
import more from "../images/more.jpg"

const Page = class extends React.Component {
    resizeSvg = () => {
        var waves = document.querySelectorAll(".photoLine svg");
        var waveWidth = window.innerWidth;
        var waveHeight = Math.round(waveWidth / 5);

        for (var i = 0; i < waves.length; i++) {
            waves[i].setAttribute("width", waveWidth);
            waves[i].setAttribute("height", waveHeight);
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeSvg);
        this.resizeSvg();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeSvg);
    }

    render() {
        return(
            <div class="meetTheTeam">
                <div class="photos show-mobile">
                    <h1>Meet the team of <em>bot experts</em></h1>
                    <div class="photoCarousel">
                        <div class="personPhoto">
                            <img src={jamesw} alt="James"/>
                            <text>James</text>
                        </div>
                        <div class="personPhoto">
                            <img src={louise} alt="louise"/>
                            <text>Louise</text>
                        </div>
                        <div class="personPhoto">
                            <img src={craig} alt="Craig"/>
                            <text>Craig</text>
                        </div>
                        <div class="personPhoto">
                            <img src={phil} alt="Phil"/>
                            <text>Phil</text>
                        </div>
                        <div class="personPhoto">
                            <img src={dimitris} alt="Dimitiris"/>
                            <text>Dimitris</text>
                        </div>
                        <div class="personPhoto">
                            <img src={william} alt="William"/>
                            <text>William</text>
                        </div>
                        <div class="personPhoto">
                            <img src={jamesb} alt="James"/>
                            <text>James</text>
                        </div>
                        <div class="personPhoto">
                            <img src={harry} alt="Harry"/>
                            <text>Harry</text>
                        </div>
                        <div class="personPhoto">
                            <img src={peter} alt="Peter"/>
                            <text>Peter</text>
                        </div>
                        <div class="personPhoto">
                            <img src={more} alt="& More"/>
                            <text>& more!</text>
                        </div>
                    </div>
                </div>
                <div class="section">
                    <h1 class="show-desktop">Meet the team of <em>bot experts</em></h1>
                    <p>We’re a group of people who not only have years of experience in designing and building <em>chatbot solutions</em> but we are deeply passionate about the power of using conversational interfaces in the right way.<br/><br/>We are a proud partner of talksuite which is an incredibly versatile, enterprise-grade <em>chatbot building platform</em>. Because of this, we can offer a unique combination of conversational design, development speed, security, stability and, ultimately, value for money.</p>
                    <h3 class="readMoreLink">Start a chat with us to learn more!</h3>
                </div>
                <div class="photos show-desktop">
                    <div class="photoLine">
                        <svg viewBox="20 0 1000 200">
                            <path d="M 0 100 q 150 -70 300 0 q 150 70 300 0 q 150 -70 300 0 q 150 70 300 0" stroke="#294664" stroke-width="40" fill="none" />
                        </svg>
                        <div class="photoContainer">
                            <div class="personPhoto">
                                <img src={jamesw} alt="James"/>
                                <text>James</text>
                            </div>
                            <div className="personPhoto">
                                <img src={louise} alt="louise"/>
                                <text>Louise</text>
                            </div>
                            <div class="personPhoto">
                                <img src={craig} alt="Craig"/>
                                <text>Craig</text>
                            </div>
                            <div class="personPhoto">
                                <img src={phil} alt="Phil"/>
                                <text>Phil</text>
                            </div>
                            <div class="personPhoto">
                                <img src={dimitris} alt="Dimitris"/>
                                <text>Dimitris</text>
                            </div>
                        </div>
                    </div>
                    <div class="photoLine">
                        <svg viewBox="20 0 1000 200">
                            <path d="M 0 100 q 150 -70 300 0 q 150 70 300 0 q 150 -70 300 0 q 150 70 300 0" stroke="#294664" stroke-width="40" fill="none" />
                        </svg>
                        <div class="photoContainer">
                            <div class="personPhoto">
                                <img src={william} alt="William"/>
                                <text>William</text>
                            </div>
                            <div class="personPhoto">
                                <img src={jamesb} alt="James"/>
                                <text>James</text>
                            </div>
                            <div class="personPhoto">
                                <img src={harry} alt="Harry"/>
                                <text>Harry</text>
                            </div>
                            <div class="personPhoto">
                                <img src={peter} alt="Peter"/>
                                <text>Peter</text>
                            </div>
                            <div class="personPhoto">
                                <img src={more} alt="& More"/>
                                <text>& more!</text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )   
    }    
}

export default Page